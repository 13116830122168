/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    em: "em",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The number of SaaS marketplaces and integration marketplaces is increasing along with the number of SaaS companies. With more than ", React.createElement(_components.a, {
    href: "https://ascendixtech.com/number-saas-companies-statistics/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "30,000 SaaS companies around the world"), " and more being started daily, it's no surprise that we've come up with ways to make it easier for people to see what's available for SaaS apps and SaaS integrations."), "\n", React.createElement(_components.p, null, "However, many of the thousands of SaaS companies that exist today do not have marketplaces. As a rule, enterprise SaaS companies like Salesforce, Intuit, and Atlassian have hubs or marketplaces for apps and integrations. On the other hand, small SaaS companies are the least likely to have an app or integration marketplace. We find mixed results in the mid-market SaaS space. In general, the greater the market share an app has, the more likely it is that other companies want to associate with it, thereby creating the partnerships that help to build app and integration marketplaces."), "\n", React.createElement(_components.h2, {
    id: "what-is-a-saas-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-a-saas-marketplace",
    "aria-label": "what is a saas marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is a SaaS marketplace?"), "\n", React.createElement(_components.p, null, "The term ", React.createElement(_components.em, null, "SaaS marketplace"), " can have one of several meanings. It can be:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "A website where a user can discover, review, and subscribe to SaaS products."), "\n", React.createElement(_components.li, null, "A SaaS app that is itself a marketplace that can be used to sell anything."), "\n", React.createElement(_components.li, null, "A feature of a SaaS app where a user can view and subscribe to add-ons, plugins, extensions, and integrations for that app."), "\n"), "\n", React.createElement(_components.p, null, "Let's examine each of these in more detail."), "\n", React.createElement(_components.h3, {
    id: "website-for-procuring-saas-products",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#website-for-procuring-saas-products",
    "aria-label": "website for procuring saas products permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Website for procuring SaaS products"), "\n", React.createElement(_components.p, null, "The SaaS marketplace (or marketplace SaaS) can be broadly defined. In the case of Amazon's ", React.createElement(_components.a, {
    href: "https://aws.amazon.com/marketplace",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "AWS Marketplace"), ", the offerings are tied to a product (AWS), but that product is a platform (instead of an app). Since AWS is the most common platform for SaaS apps worldwide, the breadth of SaaS products offered on the marketplace is probably only matched by the ", React.createElement(_components.a, {
    href: "https://azuremarketplace.microsoft.com/en-us/home",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Azure Marketplace"), ", Microsoft's equivalent for SaaS apps that run on its Azure platform."), "\n", React.createElement(_components.h3, {
    id: "saas-app-as-the-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#saas-app-as-the-marketplace",
    "aria-label": "saas app as the marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SaaS app as the marketplace"), "\n", React.createElement(_components.p, null, "The SaaS app that is the marketplace is also sometimes called a SaaS-enabled marketplace. We only mention it here for completeness, as it serves quite a different purpose than other SaaS marketplaces. Instead of the marketplace being the means of selling SaaS products, the marketplace is merely using SaaS to run the e-commerce platform."), "\n", React.createElement(_components.h3, {
    id: "feature-of-a-saas-app",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#feature-of-a-saas-app",
    "aria-label": "feature of a saas app permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Feature of a SaaS app"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://appexchange.salesforce.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Salesforce's AppExchange"), " is an example of a B2B SaaS marketplace dedicated to a single product or suite of products. AppExchange has hundreds of apps, plugins, extensions, integrations, and more. All these focus on leveraging and extending Salesforce's functionality. Similarly, ", React.createElement(_components.a, {
    href: "https://marketplace.atlassian.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Atlassian has a marketplace"), " dedicated to Atlassian's product suite."), "\n", React.createElement(_components.h2, {
    id: "what-is-an-integration-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-an-integration-marketplace",
    "aria-label": "what is an integration marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is an integration marketplace?"), "\n", React.createElement(_components.p, null, "Integration marketplaces are a subset of B2B SaaS marketplaces that provide integrations with a single product or suite of products. HubSpot's ", React.createElement(_components.a, {
    href: "https://ecosystem.hubspot.com/marketplace/apps",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "App Marketplace"), " is an excellent example, listing dozens of integrations built specifically for HubSpot."), "\n", React.createElement(_components.p, null, "The integration marketplace is a feature of a SaaS app where customers can explore the app's integration offerings, activate the ones they need, and use self-serve support tools."), "\n", React.createElement(_components.p, null, "An integration marketplace allows a B2B SaaS company to:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Provide a high-quality integration experience to its customers that, when embedded, appears to be a native part of the app"), "\n", React.createElement(_components.li, null, "Increase awareness and adoption of its integration offerings"), "\n", React.createElement(_components.li, null, "Increase product value, improve product stickiness, and increase customer retention"), "\n", React.createElement(_components.li, null, "Reduce the integration effort for its teams by allowing users to enable native integrations for themselves"), "\n", React.createElement(_components.li, null, "Reduce integration-related support calls"), "\n"), "\n", React.createElement(_components.p, null, "Within the integration marketplace, B2B SaaS customers can:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Explore all integration offerings"), "\n", React.createElement(_components.li, null, "Self-activate native integrations that connect to the other apps and services they use"), "\n", React.createElement(_components.li, null, "Monitor their active integrations using powerful logging and alerting tools"), "\n"), "\n", React.createElement(_components.h2, {
    id: "why-do-many-saas-companies-lack-an-integration-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-do-many-saas-companies-lack-an-integration-marketplace",
    "aria-label": "why do many saas companies lack an integration marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why do many SaaS companies lack an integration marketplace?"), "\n", React.createElement(_components.p, null, "With all these benefits, why don't more B2B SaaS companies have an integration marketplace?"), "\n", React.createElement(_components.p, null, "Building an integration marketplace in-house is incredibly time-consuming and expensive. With core product features competing for scarce dev resources, it's difficult for most B2B SaaS companies to carve out the time and resources to do it right."), "\n", React.createElement(_components.p, null, "As a result, some B2B SaaS companies may have integrations, but they are not visible via an integration marketplace or hub. Instead, they are often black-box solutions. Because these integrations are usually bolted onto the product rather than being genuine product features, these SaaS companies might not be interested in showcasing the integrations via a marketplace."), "\n", React.createElement(_components.p, null, "Other B2B SaaS companies have used tools such as Zapier to create integrations for their customers. Unfortunately, ", React.createElement(_components.a, {
    href: "../zapier-vs-prismatic-for-embedded-ipaas/"
  }, "this approach has substantial limitations"), ": using a system intended for straightforward internal company integrations to attempt to support complex external B2B SaaS integrations and customers at scale."), "\n", React.createElement(_components.p, null, "But this is where the embedded integration marketplace can help."), "\n", React.createElement(_components.p, null, "An embedded integration marketplace is ideal for B2B SaaS companies that cannot afford to build and manage their own in-house integration marketplace. It allows developers to focus on the core product while leveraging the marketplace to provide an outstanding customer experience. And that customer experience can increase app stickiness and reduce customer churn."), "\n", React.createElement(_components.h2, {
    id: "what-is-an-embedded-integration-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-an-embedded-integration-marketplace",
    "aria-label": "what is an embedded integration marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is an embedded integration marketplace?"), "\n", React.createElement(_components.p, null, "An embedded integration marketplace is an integration marketplace procured from a third party. A SaaS company can provide its customers with marketplace functionality without investing in marketplace development and infrastructure. It looks and feels like a feature of your SaaS app but costs far less than building everything from scratch."), "\n", React.createElement(_components.h3, {
    id: "complete-out-of-the-box-integration-ux",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#complete-out-of-the-box-integration-ux",
    "aria-label": "complete out of the box integration ux permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Complete out-of-the-box integration UX"), "\n", React.createElement(_components.p, null, "Customers can quickly locate the available native integrations, configure them to their specific needs, and activate them. Once the integrations are up and running, the customer may use built-in tools to monitor the integrations, troubleshoot issues that arise, and reconfigure integrations as necessary. The customer controls each integration and can access important status information."), "\n", React.createElement(_components.h3, {
    id: "looks-and-feels-like-your-product",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#looks-and-feels-like-your-product",
    "aria-label": "looks and feels like your product permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Looks and feels like your product"), "\n", React.createElement(_components.p, null, "The embedded integration marketplace will use SSO and is usually white-labeled and themeable to ensure that you can customize colors and design elements to match your app, giving your customers a ", React.createElement(_components.a, {
    href: "/resources/build-vs-buy-adding-native-integrations-to-your-product/"
  }, "native integration"), " deployment experience. If you want complete control over the user experience, you can often design your own UX for the integration marketplace and tie it into the API for the embedded marketplace."), "\n", React.createElement(_components.h3, {
    id: "for-a-fraction-of-the-cost-of-in-house-development",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#for-a-fraction-of-the-cost-of-in-house-development",
    "aria-label": "for a fraction of the cost of in house development permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "For a fraction of the cost of in-house development"), "\n", React.createElement(_components.p, null, "Generally speaking, you can deploy an embedded integration marketplace for a fraction of the cost it would have taken to build it in-house and can often do so in a matter of days instead of weeks or months. Because the vendor provides the embedded integration marketplace software to many B2B SaaS companies, the per-company cost is far less than it would be if a single company were to set up its own integration marketplace."), "\n", React.createElement(_components.h3, {
    id: "part-of-an-embedded-ipaas",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#part-of-an-embedded-ipaas",
    "aria-label": "part of an embedded ipaas permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Part of an embedded iPaaS"), "\n", React.createElement(_components.p, null, "The embedded integration marketplace is usually provided as part of an ", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), ". Embedded iPaaS solutions are end-to-end platforms that offer everything you need to build integrations, deploy them to customers, run them in production, and provide integration support."), "\n", React.createElement(_components.h2, {
    id: "prismatics-embedded-integration-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prismatics-embedded-integration-marketplace",
    "aria-label": "prismatics embedded integration marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Prismatic's embedded integration marketplace"), "\n", React.createElement(_components.p, null, "Our ", React.createElement(_components.a, {
    href: "/platform/embedded-integration-marketplace/"
  }, "embedded integration marketplace"), " enables each of our customers to make integrations with their product and their customer's other apps visible and accessible."), "\n", React.createElement(_components.p, null, "Our customers (B2B SaaS companies) can embed our integration marketplace, which allows them to use a powerful theming engine to control everything from colors to the corner radius for UI elements. Or, for maximum flexibility and fine-grained control of the UX, customers can use our SDK and API to create a custom integration marketplace."), "\n", React.createElement(_components.p, null, "In either case, end users can search, view, and select the integrations they need before configuring and self-activating them. Users can then monitor, reconfigure, and even troubleshoot their own integrations."), "\n", React.createElement(_components.h2, {
    id: "integration-marketplaces-are-essential-for-b2b-saas-products",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integration-marketplaces-are-essential-for-b2b-saas-products",
    "aria-label": "integration marketplaces are essential for b2b saas products permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integration marketplaces are essential for B2B SaaS products"), "\n", React.createElement(_components.p, null, "SaaS marketplaces are growing along with the SaaS apps that drive them. You've probably used a SaaS marketplace of some type and may have even considered building a SaaS marketplace for your app."), "\n", React.createElement(_components.p, null, "You need an integration marketplace if you have a B2B SaaS app that needs to share data with other common (and not-so-common) SaaS apps."), "\n", React.createElement(_components.p, null, "If you want to enable your customers' integrations as part of onboarding and not three months down the line, that's where Prismatic can help. Read our ", React.createElement(_components.a, {
    href: "/resources/integration-marketplace-for-b2b-saas/"
  }, "embedded integration marketplace guide"), ", or ", React.createElement(_components.a, {
    href: "/contact/"
  }, "reach out"), " if you'd like to learn more."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
